<!-- eslint-disable vue/no-parsing-error -->
<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156 156" height="100%" width="100%">
        <image :href="cardThumbImg" x="0" y="0" height="156" width="156" />
        <rect x="0" y="124" width="156" height="27" fill="#444564" fillOpacity="0.8" v-if="level" />

        <text x="9" y="146" width="156" height="30" fill="white" v-if="level" style="font-size:23px;letter-spacing: 1px;">
            <tspan style="">Lv.</tspan>{{ level }}
        </text>
        <image :href="frameImg" x="0" y="0" height="156" width="156" />
        <image :href="attrImg" x="0" y="0" width="36" height="36" />
        <image :href="rarityIconImg" :x="(n - 1) * 21.5 + 4.5" y="100" width="21.5" height="21.5" v-for="n in rarityStars"
            :key="n" />
        <image :href="masterRankImg" x="100" y="100" width="54" height="54" v-if="card.masterRank" />
        <!-- <image href="/dummy.png" x="0" y="0" width="156" height="156" /> -->
    </svg>
</template>
  
<script>


export default {
    name: 'card',

    props: ['card'],
    computed: {
        cardThumbImg: function () {
            ///${this.$db.cards[this.card].assetbundleName}_${this.card.defaultImage== 'special_training' ? 'after_training' : 'normal'}.png
            // console.log(`${this.$sekai.assetHost}/thumbnail/chara/${this.$db.cards[this.card].assetbundleName}_${this.card.defaultImage== 'special_training' ? 'after_training' : 'normal'}.png`);
            //return `${this.$sekai.assetHost}/thumbnail/chara/${this.$db.cards[this.card.cardId].assetbundleName}_${this.trained}.png`
            return `//mysekai.mizuki.app/sekai-jp-assets/thumbnail/chara_rip/${this.$db.cards[this.card.cardId].assetbundleName}_${this.trained}.png`
            // return `//storage.sekai.best/sekai-jp-assets/thumbnail/chara_rip/res002_no045_normal.png`
            // return this.card
        },
        trained: function () {
            return this.card.defaultImage == 'special_training' ? 'after_training' : 'normal';
        },
        level: function () {
            return this.card.level
        },
        rarity: function () {
            return this.cardData.cardRarityType.replace('birthday', 'bd').replace('rarity_', '');
        },
        rarityStars: function () {
            return this.rarity === 'bd' ? 1 : parseInt(this.rarity);
        },
        frameImg: function () {
            return `${require(`@/sprites/cardFrame_S_${this.rarity}.png`)}`;
        },
        attrImg: function () {
            let attr = this.cardData.attr;
            return `${require(`@/sprites/icon_attribute_${attr}_64.png`)}`;
        },
        rarityIconImg: function () {
            let rarityIcon = this.rarity === 'bd' ? 'birthday' : 'star_' + (this.card.specialTrainingStatus === 'done' ? 'afterTraining' : 'normal');
            return `${require(`@/sprites/rarity_${rarityIcon}.png`)}`;
        },
        masterRankImg: function () {
            return `${require(`@/sprites/masterRank_S_${this.card.masterRank}.png`)}`;
        },
        cardData: function () {
            return this.$db.cards[this.card.cardId];
        }

    },
    mounted() {
        console.log(this.card, 'card');

    }
};
</script>